import React, { useState, useEffect } from "react";
import './BroadcastFeedback.css';
import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import { hostingURL, retailerNAME } from './constant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../hooks/useModalState';
import { useConfig } from '../ConfigContext'; // Import useConfig
import { useData } from '../Context.js'; // Import useData


const BroadcastFeedback = ({ onPageChange, setShowModal }) => {
    const [behaviorRating, setBehaviorRating] = useState(0);
    const [callQualityRating, setCallQualityRating] = useState(0);
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});
    const { setModalState } = useModalContext();
    const navigate = useNavigate();
    const { configData, textData, imageData, loading: isConfigLoading } = useConfig(); // Fetch config using useConfig
    const [input, setInput] = useState(""); // Message input
    const { data } = useData(); // Access global data
    const clerkID = data.clerkID; // Retrieve clerkID

  

    useEffect(() => {
        setModalState('static');
    }, [setModalState]);

    const validate = () => {
        const newErrors = {};
        if (behaviorRating === 0) newErrors.behaviorRating = "Please rate our staff.";
        if (callQualityRating === 0) newErrors.callQualityRating = "Please rate the call quality.";
        if (comment.trim() === '') newErrors.comment = "Please provide a comment.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const RatingAPI = async () => {
        if (!validate()) return;
        const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXRhaWxlcklEIjoiaGFiaXR0IiwiaWF0IjoxNzE4MDUxMTY3fQ.k7NwgAYBHlh5YbVU-KCcMZsHo-eHSD0HZTpsVGIZyao";
        const body = {
            retailerName: retailerNAME,
            clerkID: clerkID,
            behaviorRating,
            callQualityRating,
            knowledgeRating: 5,
            comment,
        };

        try {
            const response = await axios.post('https://us-central1-testimagedownload-c7093.cloudfunctions.net/dev/addClerkRatingsForRetailers', body, {
                headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                console.log('Rating Added successfully:', response.data);
                navigateToHome();
            } else {
                console.error('Failed to add rating:', response.data);
            }
        } catch (error) {
            console.error('Error updating data', error);
        }
    };

    const handleSubmit = () => {
        if (validate()) RatingAPI();
    };

    const navigateToHome = () => {
        setShowModal(false);
        onPageChange("Body");
        window.location.reload();
    };

    return (
        <div className="container-fluid text-center d-flex flex-column saro-rating-page">
            <h3 className="headers1">{textData?.P3BT1}</h3>
            <div className="ratingStar d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <p className="Headerpara1">{textData?.P3BT2}</p>
                    <div className="rate">
                        {[5, 4, 3, 2, 1].map((rate) => (
                            <React.Fragment key={rate}>
                                <input type="radio" id={`staff-star${rate}`} name="staff-rate" value={rate} onChange={() => setBehaviorRating(rate)} />
                                <label htmlFor={`staff-star${rate}`} className="star"></label> {/* Updated to show stars */}
                            </React.Fragment>
                        ))}
                    </div>
                    {errors.behaviorRating && <p className="error">{errors.behaviorRating}</p>}
                </div>
                <div className="d-flex flex-column align-items-center rate-the-call">
                    <p className="Headerpara1">{textData?.P3BT3}</p>
                    <div className="rate">
                        {[5, 4, 3, 2, 1].map((rate) => (
                            <React.Fragment key={rate}>
                                <input type="radio" id={`call-star${rate}`} name="call-rate" value={rate} onChange={() => setCallQualityRating(rate)} />
                                <label htmlFor={`call-star${rate}`} className="star"></label> {/* Updated to show stars */}
                            </React.Fragment>
                        ))}
                    </div>
                    {errors.callQualityRating && <p className="error">{errors.callQualityRating}</p>}
                </div>
            </div>

            <div className="textmsg d-flex flex-column align-items-center">
                <textarea
                    className="comment-textarea"
                    placeholder={textData?.P5H4} // Dynamically insert textData.P1H3 or use a default value
                    onChange={(e) => setComment(e.target.value)}
                ></textarea>
                {errors.comment && <p className="error">{errors.comment}</p>}
            </div>


            {/* Added spacing between buttons and comment box */}
            <div className="btn-spacing"></div>

            <div className="d-flex justify-content-center btn-container">
                <button
                    onClick={handleSubmit}
                    style={{
                        width: '150px',
                        height: '30px',
                        fontSize: '16px',
                        borderRadius: '25px',
                        margin: '0 10px',
                        display: 'inline-block',
                        lineHeight: '0',
                       marginTop: window.innerWidth <= 768 ? '10px' : '60px',
                        backgroundColor: '#000',
                        color: '#fff',
                        border: '1px solid #000'
                    }}
                >
                    {textData?.P5Btn1}
                </button>
                <button
                    onClick={navigateToHome}
                    style={{
                        width: '150px',
                        height: '30px',
                        fontSize: '16px',
                        borderRadius: '25px',
                        margin: '0 10px',
                        display: 'inline-block',
                        lineHeight: '0',
                        marginTop: window.innerWidth <= 768 ? '10px' : '60px',
                        backgroundColor: 'transparent',
                        border: '1px solid #000',
                        color: '#000'
                    }}
                >
                    {textData?.P5Btn2}
                </button>
            </div>


            <div className={window.innerWidth <= 768 ? 'footer-containerb' : 'footer-container'}>
                <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                <span className="powered-byB">{textData?.logo}</span>
                    <img src={footerlogo1} alt="Saro Logo" className="footer-logoB" />
                    {/* <span>{textData?.logo}</span> */}
                </a>
            </div>
        </div>
    );
};

export default BroadcastFeedback;

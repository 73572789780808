import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "./Videos.css";
import { imageUrl, saroAPI } from '../constant';
import { retailerNAME } from '../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faVolumeUp, faVolumeMute, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import CyPlayer from 'cy-player';
import { useData } from '../../Context.js';



const Videos = () => {
    const { data } = useData();

    const [cart, setCart] = useState([]); // State to manage the cart
    const [loadingProduct, setLoadingProduct] = useState(null); // Manage loader for a specific product
    const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
    const [videos, setVideos] = useState([]); // State for videos
    const videoRefs = useRef([]); // Dynamic refs for multiple videos
    const sliderRef = useRef(null); // Ref for the Slider
    const [isPlaying, setIsPlaying] = useState([]); // State to track play/pause
    const [isMuted, setIsMuted] = useState([]); // State to track mute/unmute
    const [activeSlide, setActiveSlide] = useState(0); // Initialize active slide to the first video
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // For modal
    const [viewerId, setViewerId] = useState(null); // Add this state
    const [activeVideoId, setActiveVideoId] = useState(null); // Store active video ID
    const [activeVideoIndex, setActiveVideoIndex] = useState(0); 


    


    useEffect(() => {
        const fetchProductsForVideos = async () => {
            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
            try {
                const response = await fetch(
                    `${saroAPI}/fetchVideoShowsProductDetails?retailerName=${retailerNAME}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
    
                if (response.ok) {
                    const responseData = await response.json();
                    console.log("API Response for Products:", responseData);
    
                    if (responseData.products) {
                        const productsByVideoID = {};
    
                        responseData.products.forEach((product) => {
                            const videoID = product.videoShowID;
    
                            // Log for debugging
                            console.log(
                                `Processing product with ID: ${product.id} and variants: `,
                                product.variants
                            );
    
                            // Group products by `videoShowID`
                            if (!productsByVideoID[videoID]) {
                                productsByVideoID[videoID] = [];
                            }
    
                            // Avoid duplicate products (if the same product appears multiple times)
                            if (!productsByVideoID[videoID].some((item) => item.id === product.id)) {
                                // Attach the variants correctly to each product
                                productsByVideoID[videoID].push({
                                    ...product,
                                    variants: product.variants || [], // Default to an empty array if no variants
                                });
                                console.log(`Added product ${product.id} to Video ID: ${videoID}`);
                            } else {
                                console.warn(
                                    `Duplicate product ${product.id} detected for Video ID: ${videoID}`
                                );
                            }
                        });
    
                        console.log("Final Grouped Products by Video ID:", productsByVideoID);
                        setProducts(productsByVideoID); // Set products grouped by video ID
                    } else {
                        console.warn("No products found in API response.");
                        setProducts({});
                    }
                } else {
                    console.error("API Error:", response.statusText);
                    setProducts({});
                }
            } catch (error) {
                console.error("Error fetching products:", error.message);
                setProducts({});
            }
        };
    
        fetchProductsForVideos();
    }, []);
    


    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(
                    `${saroAPI}/getShopableVideos?retailerName=${retailerNAME}&pageSize=10`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${hardCodedToken}`,
                        },
                    }
                );
    
                if (response.ok) {
                    const data = await response.json();
                    setVideos(data.data.data); // Store fetched videos
                    if (data.data.data.length > 0) {
                        setActiveVideoId(data.data.data[0].videoID); // Set first video as active
                    }
                } else {
                    console.error("Failed to fetch videos:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
    
        fetchVideos();
    }, []);
    
    const productSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one product at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    //console.log("Products received in Videos:", products); // Debugging products

    // const handleAddToCart = async (product) => {
    //     setLoadingProduct(product.id);

    //     try {
    //         // const variantId = product.variantID.split('/').pop();

    //         const response = await fetch(`${saroAPI}/addToCart`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${hardCodedToken}`,
    //             },
    //             body: JSON.stringify({
    //                 retailerName: "abibas",
    //                 liveShowId: "zzAG2cCAoUYa64RulO3Q",
    //                 productId: "7819105239087",
    //                 quantity: 1,
    //                 variantId:"00",
    //                 title: product.title,
    //                 price: product.price,
    //                 image: product.image,
    //             }),
    //         });

    //         const result = await response.json();
    //         if (response.ok) {
    //             setCart((prevCart) => {
    //                 const existingItem = prevCart.find((item) => item.id === product.id);
    //                 if (existingItem) {
    //                     return prevCart.map((item) =>
    //                         item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    //                     );
    //                 }
    //                 return [...prevCart, { ...product, quantity: 1 }];
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error adding product to cart:", error);
    //     } finally {
    //         setLoadingProduct(null);
    //     }
    // };

    const handleAddToCart = async (product) => {
        console.log("🛒 Add to Cart Clicked!"); 
        console.log("📌 Product:", product);
        console.log("🎥 Video ID:", product.videoId || selectedProduct?.videoId); 
    
        const videoId = product.videoId || selectedProduct?.videoId; 
    
        if (!videoId) {
            console.error("🚨 Missing videoId!");
            alert("Error: Missing videoId!");
            return;
        }
    
        setLoadingProduct(product.id);
        try {
            const productId = product.id.split('/').pop();
            const selectedVariantId = product.selectedVariant || product.variants?.[0]?.id;
    
            if (!selectedVariantId) {
                alert("No variant selected or available.");
                return;
            }
    
            const payload = {
                retailerName: retailerNAME,
                videoId, 
                productId,
                variantId: selectedVariantId.split('/').pop(),
                quantity: 1,
                title: product.title,
                price: product.variants?.find((v) => v.id === selectedVariantId)?.price || product.price,
                image: product.image,
            };
    
            console.log("📤 Payload Being Sent:", payload); 
    
            const response = await fetch(`${saroAPI}/addToCart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify(payload),
            });
    
            const result = await response.json();
            console.log("✅ Add to Cart API Response:", result);
    
            if (response.ok) {
                if (result.viewerId) {
                    setViewerId(result.viewerId);  // ✅ Store viewer ID received from response
                    console.log("👤 Updated Viewer ID:", result.viewerId);
                }
    
                setCart((prevCart) => {
                    const existingItem = prevCart.find((item) => item.id === product.id);
                    if (existingItem) {
                        return prevCart.map((item) =>
                            item.id === product.id
                                ? { ...item, quantity: item.quantity + 1, videoId }
                                : item
                        );
                    }
                    return [...prevCart, { ...product, quantity: 1, videoId }];
                });
            }
        } catch (error) {
            console.error('❌ Error adding product to cart:', error);
        } finally {
            setLoadingProduct(null);
        }
    };
    
    
    
    
    
    const handleRemoveFromCart = async (product) => {
        if (!viewerId) {
            console.warn("No viewer ID found, skipping removal.");
            return;
        }
    
        setLoadingProduct(product.id); // Start loader for this product
        try {
            const productId = product.id.split('/').pop();
    
            const response = await fetch(`${saroAPI}/removeFromCart`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify({
                    retailerName: retailerNAME,
                    viewerId, // Use stored viewer ID
                    productId,
                }),
            });
    
            if (response.ok) {
                setCart((prevCart) => {
                    const existingItem = prevCart.find((item) => item.id === product.id);
                    if (existingItem.quantity === 1) {
                        return prevCart.filter((item) => item.id !== product.id);
                    }
                    return prevCart.map((item) =>
                        item.id === product.id
                            ? { ...item, quantity: item.quantity - 1 }
                            : item
                    );
                });
            }
        } catch (error) {
            console.error('Error removing product from cart:', error);
        } finally {
            setLoadingProduct(null); // Stop loader
        }
    };
    
    const handleCheckout = async (videoId) => {
        if (cart.length === 0) {
            alert("Your cart is empty!");
            return;
        }
    
        if (!viewerId) {  // ✅ Now checking the updated viewerId stored from Add to Cart API
            console.error("🚨 Checkout Error: Viewer ID is missing!");
            alert("Error: Viewer ID is missing. Please add an item to the cart first.");
            return;
        }
    
        if (!videoId) {
            console.error("🚨 Checkout Error: Missing videoId!");
            alert("Error: Missing videoId for checkout.");
            return;
        }
    
        console.log("🛒 Proceeding to Checkout...");
        console.log("👤 Viewer ID:", viewerId);
        console.log("🎥 Checkout Video ID:", videoId);
    
        try {
            const response = await fetch(`${saroAPI}/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify({
                    retailerName: retailerNAME,
                    viewerId,  // ✅ Now sending the latest viewer ID stored from addToCart
                    videoId,  
                }),
            });
    
            const result = await response.json();
            console.log("✅ Checkout API Response:", result);
    
            if (response.ok) {
                if (result.checkoutUrl) {
                    window.location.href = result.checkoutUrl; 
                } else {
                    alert("Checkout failed.");
                }
            } else {
                console.error("❌ Failed to initiate checkout:", result.message);
            }
        } catch (error) {
            console.error("❌ Error during checkout:", error);
        }
    };
    
    
    
    

    const isInCart = (product) => {
        return cart.some((cartItem) => cartItem.id === product.id);
    };

    // const togglePlayPause = (index) => {
    //     const video = videoRefs.current[index];
    //     if (video.paused) {
    //         video.play();
    //         setIsPlaying((prev) => {
    //             const newPlayingState = [...prev];
    //             newPlayingState[index] = true;
    //             return newPlayingState;
    //         });
    //     } else {
    //         video.pause();
    //         setIsPlaying((prev) => {
    //             const newPlayingState = [...prev];
    //             newPlayingState[index] = false;
    //             return newPlayingState;
    //         });
    //     }
    // };

    const toggleMuteUnmute = (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.muted = !video.muted;
            setIsMuted((prev) => {
                const newMutedState = [...prev];
                newMutedState[index] = video.muted;
                return newMutedState;
            });
        }
    };


    const handleShopNowClick = (product, videoId) => {
        console.log("🛍️ Opening product modal for:", product.title);
        console.log("🎥 Associated Video ID:", videoId);
    
        setSelectedProduct({
            ...product,
            variants: product.variants || [],
            selectedVariant: product.variants?.[0]?.id || null,
            videoId, // ✅ Ensure videoId is stored
        });
    
        document.body.classList.add("modal-open");
    };
    
    
    

    const closeModal = () => {
        setSelectedProduct(null); // Close modal
        document.body.classList.remove("modal-open"); // Remove class when modal closes
    };

    // useEffect(() => {
    //     videos.forEach((video, index) => {
    //         const videoElement = videoRefs.current[index];
    //         if (videoElement) {
    //             if (index === activeSlide) {
    //                 // Attempt to play and unmute the active video
    //                 // Due to browser policies, this might fail if not directly triggered by user interaction
    //                 videoElement.muted = false;
    //                 const playPromise = videoElement.play();
    //                 if (playPromise !== undefined) {
    //                     playPromise
    //                         .then(() => {
    //                             // Automatic playback started
    //                             console.log(`Video ${index} is playing and unmuted.`);
    //                         })
    //                         .catch((error) => {
    //                             // Automatic playback failed
    //                             console.error(`Failed to play video ${index}:`, error);
    //                             // Optionally keep the video muted if play fails
    //                             videoElement.muted = true;
    //                         });
    //                 }
    //             } else {
    //                 // Pause and mute inactive videos
    //                 videoElement.muted = true;
    //                 videoElement.pause();
    //                 videoElement.currentTime = 0; // Optional: Reset video to start
    //                 console.log(`Video ${video.videoID} is paused and muted.`);
    //             }
    //         }
    //     });
    // }, [activeSlide, videos]);

    const CustomPrevArrow = ({ onClick }) => {
        return (
            <div className="custom-prev-arrow" onClick={onClick}>
                &#10094;
            </div>
        );
    };
    
    const CustomNextArrow = ({ onClick }) => {
        return (
            <div className="custom-next-arrow" onClick={onClick}>
                &#10095;
            </div>
        );
    };

    useEffect(() => {
        console.log('VideoEffect called when user clicked next video');
        videoRefs.current.forEach((videoEl, idx) => {
          if (!videoEl) return;
          
          if (idx === activeVideoIndex) {
            // This is the active one – you decide if you want to auto-play it
            // videoEl.play(); // or keep it paused if you want explicit user click
          } else {
            // Pause and reset others
            videoEl.pause();
            videoEl.currentTime = 0; 
          }
        });
      }, [activeVideoIndex]);
      
    

    const sliderSettings = {
        dots: false,
        infinite: true, // Disable infinite scrolling
        speed: 500,
        slidesToShow: 3, // Adjust based on design needs
        slidesToScroll: 1,
        centerMode: false, // Disable center mode to prevent showing partial slides
        // If you want to keep centerMode, set centerPadding to '0px'
        centerMode: true,
        centerPadding: '0px',
        autoplay: false,
        autoplaySpeed: 4000,
        vertical: false,
        afterChange: (current) => {
            console.log(`Current active slide: ${current}`);
            setActiveSlide(current);
            setActiveVideoIndex(current);
        },
        // Optional: Customize arrows if needed
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                },
            },
            // {
            //     breakpoint: 600,
            //     settings: {
            //         slidesToShow: 2,
            //     },
            // },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1, // Full-screen video
                    centerMode: false,
                    centerPadding: '0px',
                    arrows: true, // Remove arrows for better mobile UX
                    swipeToSlide: true, // Enable touch-based swiping
                    touchThreshold: 10, // Responsive touch sensitivity
                    verticalSwiping: true, // Ensure smooth vertical scrolling
                },
            },
        ],
    };
    

    return (
        <div className="video-carousel">
            {/* Updated Slider Settings */}
            {console.log("Rendering video carousel with videos:", videos)}
            <Slider  {...sliderSettings} ref={sliderRef}
            >
                {videos.map((video, index) => {
                    console.log(`Rendering video #${index} with ID: ${video.videoID}`);
                    const isActive = index === activeVideoIndex;
                    return (
                        <div key={video.videoID} className="video-slide" >
                            {/* Updated Video Section */}
                            <div className="video-container">
                                <div className="video-wrapper">
                                <div className="video-top-blur"></div>
                                    
                                    <video
                                        className="carousel-video"
                                        poster={video.thumbnail}
                                        src={isActive ? video.videoUrl : undefined}
                                        preload="none"
                                        autoPlay={isActive} 
                                        loop
                                        muted
                                        controls
                                        ref={(el) => (videoRefs.current[index] = el)} // Correctly assign ref for each video
                                        onClick={() => {
                                            console.log(`Video #${index} clicked`);
                                            sliderRef.current.slickGoTo(index);
                                        }}
                                    ></video>

                                    <div className="video-title-container">

                                        {/* <FontAwesomeIcon
                                                icon={faEllipsisH}
                                                className="icon menu-icon"
                                                title="More Options"
                                            /> */}
                                        <p className="video-title">{video.title}</p>
                                        {/* <FontAwesomeIcon
                                                icon={isMuted[index] ? faVolumeMute : faVolumeUp} // Change icon based on mute state
                                                className={`icon speaker-icon ${isMuted[index] ? "muted" : "unmuted"}`}
                                                title={isMuted[index] ? "Unmute Video" : "Mute Video"}
                                                onClick={() => toggleMuteUnmute(index)} // Call the toggle function
                                            /> */}

                                    </div>

                                    {/* Updated Product Carousel Section */}
                                    {products[video.videoID] && products[video.videoID].length > 0 && (
                                        <div className="product-card-carousel">
                                            <Slider
                                                dots={false}
                                                infinite={false}
                                                speed={500}
                                                slidesToShow={1} // Show one product at a time
                                                slidesToScroll={2}
                                            >
                                                {products[video.videoID].map(
                                                    (product, productIndex) => {
                                                        return (
                                                            <div
                                                                key={productIndex}
                                                                className="product-card"
                                                            >
                                                                {selectedProduct?.id !== product.id && (
                                                                    <div className="product-card-content">
                                                                        {/* Header section with image, title, and price */}
                                                                        <div className="product-header">
                                                                            <img className="product-image" src={product.image} alt={product.title} />

                                                                            <div className="product-info">
                                                                                <span className="product-title">{product.title}</span>
                                                                                <span className="product-price">
    {product.variants?.[0]?.price || product.price} {product.currency}
</span>

                                                                            </div>
                                                                        </div>

                                                                        {/* Shop Now Button */}
                                                                        <button className="shop-now-modal-button" onClick={() => handleShopNowClick(product, video.videoID)}>
    Shop Now &gt;
</button>

                                                                    </div>



                                                                )}
                                                                {selectedProduct?.id === product.id && (
                                                                    <div className="product-modal">
                                                                        <div className="blur-overlay"></div>
                                                                        <div className="modal-content">
                                                                            <button className="close-button" onClick={closeModal}>
                                                                                &times;
                                                                            </button>
<div className="scrollable-content">
    <img
        className="modal-product-image"
        src={selectedProduct.image}
        alt={selectedProduct.title}
    />
    <h2 className="modal-product-title">{selectedProduct.title}</h2>

    {/* Variant Selector */}
{/* Variant Selector */}
{selectedProduct.variants.length > 0 ? (
    <div className="variant-selector">
        <h3>Select Variant:</h3>
        <select
            value={selectedProduct.selectedVariant}
            onChange={(e) =>
                setSelectedProduct({
                    ...selectedProduct,
                    selectedVariant: e.target.value,
                })
            }
        >
            {selectedProduct.variants.map((variant) => (
                <option key={variant.id} value={variant.id}>
                    {variant.title || "Variant"} - {variant.price} {selectedProduct.currency}
                </option>
            ))}
        </select>
    </div>
) : (
    <p>No variants available</p>
)}

<p className="modal-product-price">
    {
        selectedProduct.variants.find((variant) => variant.id === selectedProduct.selectedVariant)?.price 
        || selectedProduct.price
    } {selectedProduct.currency}
</p>

    <h2 className="modal-product-description-heading">Description</h2>
    <p className="modal-product-description">
        {selectedProduct.description || "No description available."}
    </p>
</div>

                                                                            {cart.some((cartItem) => cartItem.id === product.id) ? (
                                                                                <div className="cart-actions">
<button 
    className="checkout-btn" 
    onClick={() => handleCheckout(selectedProduct.videoId)}
>
    Checkout
</button>


                                                                                    <button
                                                                                         className="quantity-btn"
                                                                                        onClick={() => handleRemoveFromCart(product)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faMinusCircle} />
                                                                                    </button>

                                                                                    <span className="cart-quantity">
                                                                                        {cart.find((item) => item.id === product.id)?.quantity || 1}
                                                                                    </span>

                                                                                    <button
                                                                                        className="quantity-btn"
                                                                                        onClick={() => handleAddToCart(product)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                                                    </button>
                                                                                </div>

                                                                            ) : (
<button 
    className="shop-now-modal-button"
    onClick={() => {
        console.log("🛒 Add to Cart from Modal Clicked!");
        console.log("📌 Selected Product:", selectedProduct);
        console.log("🎥 Video ID:", selectedProduct.videoId);

        handleAddToCart(selectedProduct);
    }}
>
    Add to Cart
</button>

                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Slider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );


};

export default Videos;

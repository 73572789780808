import React from 'react';
import './Decision.css';
import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import decisionImage from '../images/Decision.png'
import { useConfig } from '../ConfigContext'; // Import useConfig


const Decision = ({ onPageChange }) => {
    const { configData, textData, imageData, loading: isConfigLoading } = useConfig(); // Fetch config using useConfig

  const handleOneToOne = () => {
    console.log('Navigating to One-to-One Video Call');

    onPageChange('Body'); // Navigate to "Body" page
  };

  const handleGoLive = () => {
    onPageChange('GoLive'); // Navigate to "GoLive" page
  };

  // if (isConfigLoading) {
  //   // Display a loader while data is loading
  //   return (
  //     <div className="loader-container">
  //       <div className="loader"></div>
    
  //     </div>
  //   );
  // }

  return (
    <div className="decision-container">
      <h1 className="decision-heading">Choose how you would like to join one to one or broadcast</h1>
      <hr className="decision-divider" />
      <div className="decision-options">
        <div className="decision-option">
          <div className="image-container">
            <img
              src={decisionImage} // Replace with the actual image URL
              alt="One-to-One"
              className="decision-image"
            />
          </div>
          <div className="text-container">
            <h2 className="decision-title">One-to-One Video Call</h2>
            <p className="decision-description">
              Try again after some time. All the clerks are busy right now...
            </p>
            <button
              className="decision-btn one-to-one"
              onClick={handleOneToOne}
            >
              One-to-One
            </button>
          </div>
        </div>
        <div className="decision-option">
          <div className="image-container">
            <img
              src={decisionImage} // Replace with the actual image URL
              alt="Join Live"
              className="decision-image"
            />
          </div>
          <div className="text-container">
            <h2 className="decision-title">Join Live Session</h2>
            <p className="decision-description">
              There is no on-going live session. Please come back another time.
            </p>
            <button
              className="decision-btn go-live"
              onClick={handleGoLive}
            >
              Join live
            </button>
          </div>
        </div>
      </div>
      <footer className="decision-footer">
        {/* Powered by <span className="decision-brand"></span> */}
        <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  <span className="powered-by">{textData?.logo}</span>
                  <img src={footerlogo1} alt="Saro Logo" className="footer-logo" />
                </a>
      </footer>
    </div>
  );
};

export default Decision;

import React from 'react';
import './App.css';
import Header from './Components/Header';
import Body from './Components/Body';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Headers from './Components/SpeakPage/Headers';
import Main from './Components/CallPage/Main';
import FeedPage from './Components/FeedBack/FeedPage';
import FirstPage from './Components/FirstPage/FirstPage';
import WebPage from './Components/WebPage/WebPage';
import Permission from './Components/Permission';
import GoLive from './Components/GoLive';
import { DataProvider } from './Context';
import { ConfigProvider } from './ConfigContext';
import { StateProvider } from './StateProvider'; // Import StateProvider
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


// Initial state and reducer for StateProvider
const initialState = {
  user: { displayName: "Guest User" },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.user };
    default:
      return state;
  }
};

function App() {
  return (
    <Router>
      <div className="app-container">
        <StateProvider initialState={initialState} reducer={reducer}> {/* Wrap with StateProvider */}
          <DataProvider>
            <ConfigProvider>
              <Routes>
                <Route path="*" element={<WebPage />} />
                <Route path="Components/GoLive" element={<GoLive />} />
                <Route path="Components/Body" element={<Body />} />
                <Route path="SpeakPage/Headers" element={<Headers />} />
                <Route path="CallPage/Main" element={<Main />} />
                <Route path="FeedBack/FeedPage" element={<FeedPage />} />
                <Route path="FirstPage/FirstPage" element={<FirstPage />} />
                <Route path="WebPage/WebPage" element={<WebPage />} />
                <Route path="Components/Permission" element={<Permission />} />
              </Routes>
            </ConfigProvider>
          </DataProvider>
        </StateProvider>
      </div>
    </Router>
  );
}

export default App;

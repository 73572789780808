const environments = {
  dev: {
    hostingURL: 'https://testimagedownload-c7093.web.app/',
    API_URL: 'https://us-central1-testimagedownload-c7093.cloudfunctions.net',
    channel: '/dev',
    imageHostingUrl: 'https://testimagedownload-c7093.web.app'
  },
  sit: {
    hostingURL: 'https://wakapapa-fd7cb.web.app/',
    API_URL: 'https://us-central1-wakapapa-fd7cb.cloudfunctions.net',
    channel: '/sit',
    imageHostingUrl: 'https://wakapapa-fd7cb.web.app'
  },
  trial: {
    hostingURL: 'https://shop-ar-online-trial.web.app/',
    API_URL: 'https://us-central1-shop-ar-online-trial.cloudfunctions.net',
    channel: '/trial',
    imageHostingUrl: 'https://shop-ar-online-trial.web.app/'
  },
  production: {
    hostingURL: 'https://shop-ar-online.web.app',
    API_URL: 'https://us-central1-shop-ar-online.cloudfunctions.net',
    channel: '/production',
    imageHostingUrl: 'https://shop-ar-online.web.app'
  }
};

// Set the current environment based on an environment variable
const currentEnv = environments[process.env.REACT_APP_ENV || 'dev'];

const { hostingURL, API_URL, channel, imageHostingUrl } = currentEnv;

// Dynamic API URL WITH CHANNELS
export const saroAPI = `${API_URL}${channel}`;
export const imageUrl = imageHostingUrl;

let retailerNAME;
let siteLOGO;

// Conditional logic based on the environment
if (process.env.REACT_APP_ENV === 'dev') {
  retailerNAME = 'khazanay';
  siteLOGO = '';
  // siteLOGO = 'https://sit.shop-ar-online.com/wp-content/uploads/2019/06/organic-store-logo5.svg';
} else {
  // Ensure that container is properly handled and defaults are set
  const container = document.querySelector('#ShopARonline');
  retailerNAME = container ? container.dataset.param1 : 'defaultRetailerName';
  siteLOGO = '';
  // siteLOGO = container ? container.dataset.param2 : 'https://default-logo-url.com';
}

// retailerNAME = 'abibas';

// console.log(`Using ${process.env.REACT_APP_ENV || 'dev'} environment:`);
// console.log(`Hosting URL: ${hostingURL}`);
// console.log(`API URL: ${API_URL}`);
// console.log(`Channel: ${channel}`);
// console.log(`SARO API: ${saroAPI}`);

// Export the constants
export { hostingURL, retailerNAME, siteLOGO };
